import React from "react";
import classes from "./serviceCards.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const ServiceCard  = ({title,description,image,points,imageLeft,sectionColor, project}) => {
    const imageColClass = imageLeft ? "order-sm-first" : "order-sm-last";
    const navigate = useNavigate('/');
  return (
    <div className={classes[sectionColor]}>

    
    <section className={`py-5 container-lg  ${classes["card-container"]} `}>
      <div className="row justify-content-center   mx-0 align-items-center">
        <div className={`col-sm-6 mb-4 mb-sm-0 col-12 ${imageColClass}`}>
          <img src={image} alt={title} className=" rounded-3" />
        </div>
        <div className={`col-sm-6 col-12`}>
          <div className={`${classes["section-text"]}`}>
            <h1 className="mb-lg-4 mb-2">{title}</h1>
            <p >
              {description}
            </p>
            <ul className={classes["logo-list"]}>
              {
                points.map((item)=>(
                  <li>
                    <FontAwesomeIcon icon={faCheck} size="lg" className={classes.iconStyle} />
                      {item}
                  </li>
                ))
              }
            </ul>
            <button onClick={()=>(navigate(`/portfolio${project}`))}>View Project</button>
          </div>
        </div>

        
      </div>
    </section>
    </div>
  );
};

export default ServiceCard ;
