import React from 'react';

import PagesHeader from "../components/Layout/PagesHeader";

import Footer from "../components/Footer/Footer";
import Contact from "../components/Layout/Contact";
import OurTech from "../components/Layout/OurTech";

import Experience from '../components/Layout/Experience';
import AboutUs from '../components/Layout/AboutUs';

import Values from '../components/Layout/about/Values';
import WhyUs from '../components/Layout/about/WhyUs';
import ProjectOverview from '../components/Layout/about/ProjectOverview';

const About = ()=>{
    return(
        <>
        <PagesHeader title="About Us" description="Canvax Lab specializes in pioneering digital solutions that inspire and engage. We're dedicated to crafting exceptional experiences in web development, design, and digital marketing, pushing the boundaries of creativity and functionality. " />
          {/* <WhyChooseUs/> */}
          <div style={{borderTop:'1px solid #636363'}}><Values /></div>
          <ProjectOverview/>
          <WhyUs/>
        {/* <BottomLine/> */}
        {/* <PortfolioBanner
        title="We are Canvax Logo Designs"
        description="Whether starting a new business or revamping an established one, we will make your full scope of digital dreams a reality. With an extensive range of custom services and carefully curated team of experts, we take your online and offline presence to the next level."
      /> */}

        <AboutUs showBtn={false}/>
        <OurTech/>
        <Experience/>
        <Contact/>
        <Footer/>
        </>
    )

}
export default About;