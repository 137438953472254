import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import classes from './Whatsapp.module.css'

import React from 'react';



const Whatsapp = () => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Chat with us on Whatsapp
        </Tooltip>
      );
  return (
    <>
    {/* <Link to="/Whatsapp" target="_blank"> */}
    <a href="https://wa.link/pndk8m" target="_blank" rel="noopener noreferrer">
    <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
        
            <div className={classes.whatsapp}>
                <FontAwesomeIcon icon={faWhatsapp} size="lg" className={classes.icon}/>
            </div>
        
      </OverlayTrigger>
    </a>
    </>
  )
}

export default Whatsapp