import { Link } from "react-router-dom";

import logo from "../../assets/CANVAXLAB.png";
import brand1 from "../../assets/brand1.png";
import brand2 from "../../assets/brand2.png";
import brand3 from "../../assets/brand3.png";
import brand4 from "../../assets/brand4.png";
import brand5 from "../../assets/brand5.png";

import classes from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker, faPaperPlane, faPhone } from "@fortawesome/free-solid-svg-icons";

const services = [
  { link: "/services#uiux-design", text: "UI/UX Design" },
  { link: "/services#graphic-design", text: "Graphic Design" },
  { link: "/services#3d-animation", text: "3D Animation" },
  { link: "/services#mobile-web-app", text: "Mobile App" },
  { link: "/services#website-development", text: "Web Development" },
  { link: "/services#social-media-marketing", text: "Digital Marketing" },
];

const Footer = () => {
  return (
    <>
      <section className={classes.Footer}>
        <div className="container-lg py-4 px-4">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className={classes["brandlo-footer-img"]}>
                <img src={logo} alt="logo" />
                <p>
                  A creative, technical, full service, website designing and
                  development agency, building beautiful, attractive and
                  affordable masterpiece for small and large businesses.
                </p>
              </div>
            </div>
            <div className="col-md-2 col-sm-6">
              <div className={classes["brandlo-footer-heading"]}>
                <h2>Services</h2>
                {services.map((item) => (
                  <div>
                    <Link to={item.link} className={classes.link}>
                      {item.text}
                    </Link>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-md-3 col-sm-6">
              <div className={classes["brandlo-footer-heading"]}>
                <h2>Follow Us</h2>
                <div>
                  <Link
                    to="https://www.facebook.com/canvaxlab.official"
                    className={classes.link}
                  >
                    Facebook
                  </Link>
                </div>
                <div>
                  <Link
                    to="https://www.linkedin.com/company/canvaxlab/"
                    className={classes.link}
                  >
                    Linkedin
                  </Link>
                </div>
                <div>
                  <Link to="http://x.com/canvaxlab" className={classes.link}>
                    Twitter
                  </Link>
                </div>
                <div>
                  <Link
                    to="https://www.instagram.com/canvaxlab.official/"
                    className={classes.link}
                  >
                    Instagram
                  </Link>
                </div>
                <div>
                  <Link
                    to="https://www.youtube.com/@CanvaxLab"
                    className={classes.link}
                  >
                    Youtube
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className={classes["brandlo-footer-heading"]}>
                <h2>Contact</h2>

                <p>
                  <div class="icon d-flex align-items-center justify-content-start">
                     <FontAwesomeIcon
                    icon={faPhone}
                    size="lg"
                    className={classes.iconStyle}
                  />
                    +44 748 7234443
                  </div>
                </p>
                <p>
                  <div class="icon d-flex align-items-center justify-content-start">
                    <FontAwesomeIcon
                      icon={faPaperPlane}
                      size="lg"
                      className={classes.iconStyle}
                    />
                    info@canvaxlab.com
                  </div>
                </p>
                <p>
                  <div class="icon d-flex align-items-center justify-content-start">
                    <FontAwesomeIcon
                      icon={faMapMarker}
                      size="lg"
                      className={classes.iconStyle}
                    />
                    473 Mundet Place, Hillside, NJ 07205
                  </div>
                </p>
                <p>
                  <div class="icon d-flex align-items-center justify-content-start">
                    <FontAwesomeIcon
                      icon={faMapMarker}
                      size="lg"
                      className={classes.iconStyle}
                    />
                    12 Manasty Rd Peterborough PE2 6UP United Kingdom
                  </div>
                </p>
              </div>
            </div>
            <div className="col-12 mx-auto">
              <div className={classes.brandImage}>
                <div>
                  <img src={brand1} alt="" />
                </div>
                <div>
                  <img src={brand2} alt="" />
                </div>
                <div>
                  <img src={brand3} alt="" />
                </div>
                <div>
                  <img src={brand4} alt="" />
                </div>
                <div>
                  <img src={brand5} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={classes["footer-line"]}>
        <div className={`${"container-lg"} `}>
          <div className={`${"row pt-4 px-2"} `}>
            <div className="col-sm-5">
              <p>© Canvax Lab 2022. All rights reserved.</p>
            </div>
            <div className="col-sm-2">
              <p>Privacy Policy</p>
            </div>
            <div className="col-sm-2">
              <p>Terms &amp; Conditions.</p>
            </div>
            <div className="col-sm-3">
              <Link to="/createrLink" target="_blank">
                <p>By CanvaxLab.</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
