import React from "react";
import classes from "./about.module.css";
const WhyUs = () => {
  return (
    <section className="py-5" style={{ backgroundColor: "#101010" }}>
      <div className={`container-lg ${classes.section} px-3 px-lg-0`}>
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6">
            <h1 className="mb-4">
              Our <span>journey</span> so far
            </h1>
            <p>
              In the ever-evolving landscape of technology, we've established
              ourselves as catalysts for change and innovation. Our services
              encompass the development of cutting-edge solutions, strategic
              consulting, and comprehensive software distribution. We address
              the intricate needs of diverse sectors, including commercial
              industries, research entities, and educational institutions, with
              a commitment to advancing the technological forefront.
              
              <span className="d-block mt-4">
                Our team is a collective of experts—strategists, engineers, and thought leaders—each bringing a wealth of knowledge and experience. 
              </span>
            </p>
            <img src="/img/icons/stars.svg" alt="stars" className="mt-4" />
            <div className="row justify-content-start align-items-start mt-2">
              <div
                className={` col-5 ${classes["metric-card"]}  border-top mt-4 `}
              >
                <h3>100%</h3>
                <div className={`${classes["light-opacity-text"]}`}>
                  Satisfaction guarantee
                </div>
              </div>
              <div
                className={`col-5 ${classes["metric-card"]}  border-top mt-4  ms-4`}
              >
                <h3 style={{ color: "rgb(210, 172, 71)" }}>#1</h3>
                <div className={`${classes["light-opacity-text"]}`}>
                  Rated agency
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 d-md-block d-none">
            <img
              src="/img/img/abo1.png"
              alt=""
              className={`w-75 ${classes["main-img"]}`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
