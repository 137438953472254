import React from "react";
import classes from './about.module.css';

const ProjectOverview = () => {
  return (
    <section className={classes["overview-section"]}>
      <div className="container-lg py-5">   
        <div className="row align-items-center justify-content-center">
            <div className="col-sm-3 col-0 text-center">
                <h1>QUICK <br/>OVERVIEW</h1>
            </div>
            <div className="col-sm-3 col-4 text-center">
                <h2>50+</h2>
                <span>EMPLOYEES</span>
            </div>
            <div className="col-sm-3 col-4  text-center">
                <h2>99+</h2>
                <span>PROJECTS</span>
            </div>
            <div className="col-sm-3 col-4  text-center">
                <h2>70+</h2>
                <span>CLIENTS</span>
            </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectOverview;
