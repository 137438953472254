import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import classes from './ModalView.module.css';

function ModalView(props) {
  const ModelClassName = props.status ? `${classes.Modal}` : `${classes.ModalDanger}`;

  const ModelIconClass = props.status ? classes.iconSuccess : classes.iconError
  const Modelheading = props.status && classes.successColor
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="sm-down"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName={ModelClassName}
      >
        <Modal.Header closeButton className={classes.ModalHead} closeVariant='white'>
        </Modal.Header>
        <Modal.Body className={classes.ModalBody}>
        <div className="row justify-content-center align-items-center pb-2">
          <div className="col-1">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className={ModelIconClass}
            />
          </div>
          <div className="col-10">
            <h2 className={`${classes.ModalText} ${Modelheading}`}>{props.title}</h2>
            <p>{props.text}</p>
          </div>
        </div>
      </Modal.Body>
      </Modal>
    );
  }



export default ModalView;