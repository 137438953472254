import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PagesHeader from '../components/Layout/PagesHeader'
import Contact from '../components/Layout/ContactPage'
import Footer from '../components/Footer/Footer'
import ServiceCard  from '../components/Layout/Services/ServiceCard'


const serviceData = [
  {
    id: 'uiux-design',
    title: 'UI/UX Design',
    description: 'We create intuitive and user-centered design solutions that enhance user experience and maximize engagement',
    points: ["User Interface Design", "Experience Mapping", "Prototyping & Wireframing", "Usability Testing"],
    image: "/img/img/uiux.webp",
    imageLeft: false,
    sectionColor: 'dark',
    project:'#websites',
  },
  {
    id: 'graphic-design',
    title: 'Graphic Design',
    description: "Our graphic design services communicate your brand's message through visually stunning and memorable designs.",
    points: ["Branding & Identity", "Print Materials", "Packaging Design", "Infographics"],
    image: "/img/img/gd.jpg",
    imageLeft: true,
    sectionColor: 'light',
    project:'#logo',
  },
  {
    id: '3d-animation',
    title: '3D Animation',
    description: "Bring your concepts to life with our 3D animation services, perfect for impactful storytelling and visualizations.",
    points: ["Character Animation", "Product Visualizations", "Architectural Renderings", "Motion Graphics"],
    image: "/img/img/3dani1.jpg",
    imageLeft: false,
    sectionColor: 'dark',
    project:'#animations',
  },
  {
    id: 'website-development',
    title: 'Website Development',
    description: "We specialize in developing responsive, high-performance websites that drive business growth and customer engagement.",
    points: ["Responsive Web Design", "E-commerce Solutions", "CMS Development", "Web Applications", "Wordpress"],
    image: "/img/img/webdev.jpg",
    imageLeft: true,
    sectionColor: 'light',
    project:'#websites',
  },
  {
    id: 'mobile-web-app',
    title: 'Mobile App Development',
    description: "We build mobile applications that offer compelling functionality and optimal user experience across all platforms.",
    points: ["iOS & Android Apps", "Cross-Platform Development", "App Store Optimization", "Mobile UI/UX"],
    image: "/img/img/mobileapp.jpg",
    imageLeft: false,
    sectionColor: 'dark',
    project:'#mobileApps',
  },
  {
    id: 'seo',
    title: 'SEO',
    description: "Increase your visibility on search engines and attract more organic traffic with our tailored SEO strategies.",
    points: ["Keyword Research", "On-page SEO", "Link Building", "SEO Analytics"],
    image: "/img/img/seo1.jpg",
    imageLeft: true,
    sectionColor: 'light',
    project:'',
  },
  {
    id: 'social-media-marketing',
    title: 'Social Media Marketing',
    description: "Engage with your audience and grow your brand presence with our targeted social media marketing campaigns.",
    points: ["Content Creation", "Social Media Strategy", "Brand Awareness Campaigns", "Analytics & Reporting"],
    image: "/img/img/dmark.avif",
    imageLeft: false,
    sectionColor: 'dark',
    project:'',
  },
  {
    id: 'support-maintenance',
    title: 'Support and Maintenance',
    description: "Keep your digital assets running smoothly with our comprehensive support and maintenance services.",
    points: ["Ongoing Website Maintenance", "Technical Support", "Performance Optimization", "Security Updates"],
    image: "/img/img/mobileapp.jpg",
    imageLeft: true,
    sectionColor: 'light',
    project:'',
  },
];
const Services = () => {
   const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);
  return (
    <>
        <PagesHeader title="Comprehensive Services for Modern Needs" description="At Canvax Lab, we offer an array of digital services to bring your ideas to life. From initial design to final launch, our dedicated team provides tailored solutions in web development, app creation, and digital marketing. We're committed to excellence at every step, ensuring we meet your needs with precision and creativity." />
          <div style={{borderTop:'1px solid #636363'}}>

          
          {
            serviceData.map((item)=>( <div id={item.id} key={item.id}>
              <ServiceCard {...item} />
            </div>))
          }

        
          </div>
        <Contact/>
        <Footer />
    </>
  )
}

export default Services