
import useInput from "../hooks/use-input";
import useHttp from "../hooks/use-http";

import { useState } from "react";

import ModalView from "../UI/ModalView";
import classes from "./Contact.module.css";

const isEmpty = (value) => value.trim() !== "";
const Contact = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const {sendRequest} = useHttp();

  let today = new Date()
  const currentdateTime = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() +' '+  today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();


  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: enteredNameHasError,
    valueChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    reset: nameReset,
  } = useInput(isEmpty);

  const {
    value: enteredPhoneNumber,
    isValid: enteredPhoneNumberIsValid,
    hasError: enteredPhoneNumberHasError,
    valueChangeHandler: PhoneNumberChangeHandler,
    inputBlurHandler: PhoneNumberBlurHandler,
    reset: PhoneNumberReset,
  } = useInput(isEmpty);

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: enteredEmailHasError,
    valueChangeHandler: EmailChangeHandler,
    inputBlurHandler: EmailBlurHandler,
    reset: EmailReset,
  } = useInput((value) => value.includes("@"));

  const {
    value: enteredMessage,
    isValid: enteredMessageIsValid,
    hasError: enteredMessageHasError,
    valueChangeHandler: MessageChangeHandler,
    inputBlurHandler: MessageBlurHandler,
    reset: MessageReset,
  } = useInput(isEmpty);

  const {
    value: enteredSubject,
    isValid: enteredSubjectIsValid,
    hasError: enteredSubjectHasError,
    valueChangeHandler: SubjectChangeHandler,
    inputBlurHandler: SubjectBlurHandler,
    reset: SubjectReset,
  } = useInput(isEmpty);


  const enterDataHandler = async(Data)=>{
    const responseConfiq ={
      url: "contactForm.json",
      method: "POST",
      headers:{
        "Content-Type": "application/json",
      }, 

      body:{
        CustomerName: enteredName,
        phoneNo: enteredPhoneNumber,
        email: enteredEmail,
        subject:enteredSubject,
        message: enteredMessage,
        date:currentdateTime
      }

    }
    sendRequest(responseConfiq);
  }

  const onSubmitformHandler = (event) => {
    event.preventDefault();
    if (!enteredNameIsValid ||!enteredPhoneNumberIsValid ||!enteredMessageIsValid ||!enteredEmailIsValid || !enteredSubjectIsValid) 
    {
      setModalShow(true);
      setModalStatus(false);
      return;
    }
    

    const customerData = {
      name: enteredName,
      phoneNo: enteredPhoneNumber,
      email: enteredEmail,
      subject:enteredSubject,
      message: enteredMessage,
      date:currentdateTime
    };

    enterDataHandler(customerData);
    setModalShow(true);
    setModalStatus(true);

    nameReset();
    PhoneNumberReset();
    EmailReset();
    SubjectReset();
    MessageReset();
  };
  const Modaltext = modalStatus ? "Our Agent Will be in Touch with u within 24 Hours." : "Please fill All Fields Correctly and make sure none of the Field is Red";
  const Modaltitle = modalStatus ? "We Have Received your Details." : "Error Submiting";
  return (
    <>
    {modalShow &&
      <ModalView show={modalShow} onHide={() => setModalShow(false)} text={Modaltext} title={Modaltitle} status={modalStatus}/> 
    }
    <div className="container py-5 text-center">
      <div className={classes.contactText}>
        <h6>Get in Touch</h6>
        <h2>Contact Us</h2>
      </div>

      <form onSubmit={onSubmitformHandler}>
        <div className="row">
          <div className="col-sm-4 px-1">
            <div className={`${"form-floating mb-3 mt-3"}`}>
              <input
                type="text"
                placeholder="Name"
                className={enteredNameHasError? ` ${classes.inputError} ${"form-control"} ${"is-invalid"} ${classes["form-custom"]}`: `${"form-control"} ${classes["form-custom"]}`}
                value={enteredName}
                onChange={nameChangeHandler}
                onBlur={nameBlurHandler}
                minLength="3"
              />
              <label htmlFor="name" className={classes.label}>Name</label>
            </div>
          </div>

          <div className="col-sm-4  px-1">
            <div className={`${"form-floating mb-3 mt-3"}`}>
              <input
                type="text"
                placeholder="Email"
                className={enteredEmailHasError ? `${classes.inputError} ${"form-control"} ${"is-invalid"} ${classes["form-custom"]}`: `${"form-control"} ${classes["form-custom"]}`}
                value={enteredEmail}
                onChange={EmailChangeHandler}
                onBlur={EmailBlurHandler}
    
              />
              <label htmlFor="name" className={classes.label}>Email</label>
            </div>
          </div>
          <div className="col-sm-4  px-1">
            <div className={`${"form-floating mb-3 mt-3"}`}>
              <input
                type="number"
                placeholder="Phone"
                className={enteredPhoneNumberHasError ? `${classes.inputError} ${"is-invalid"} ${"form-control"} ${classes["form-custom"]}`: `${"form-control"} ${classes["form-custom"]}`}
                value={enteredPhoneNumber}
                onChange={PhoneNumberChangeHandler}
                onBlur={PhoneNumberBlurHandler}
            
              />
              <label htmlFor="name" className={classes.label}>Phone</label>
            </div>
          </div>
          <div className="col-4 px-1">
            <div className={`${"form-floating mb-3 mt-3"}`}>
              <input
                type="text"
                placeholder="Subject"
                className={ enteredSubjectHasError? `${classes.inputError} ${"is-invalid"} ${"form-control"} ${classes["form-custom"]}`: `${"form-control"} ${classes["form-custom"]}`}
                value={enteredSubject}
                onChange={SubjectChangeHandler}
                onBlur={SubjectBlurHandler}
             
              />
              <label htmlFor="Message" className={classes.label}>Subject</label>
            </div>
          </div>
          
          <div className="col-8 px-1">
            <div className={`${"form-floating mb-3 mt-3"}`}>
              <input
                type="text"
                placeholder="Messgae"
                className={ enteredMessageHasError? ` ${classes.inputError} ${"is-invalid"} ${"form-control"} ${classes["form-custom"]}`: `${"form-control"} ${classes["form-custom"]}`}
                value={enteredMessage}
                onChange={MessageChangeHandler}
                onBlur={MessageBlurHandler}
              
              />
              <label htmlFor="Message" className={classes.label}>Message</label>
            </div>
          </div>

        </div>
        <button type="submit" className={classes.SubmitButton}>
          Get in Touch
        </button>
      </form>
    </div>
    </>
  );
};

export default Contact;
