import OffCanvasNavbar from "./components/Header/Navbar/Navbar"


const Layout = (props)=>{
    return(<OffCanvasNavbar>{props.children}</OffCanvasNavbar> 


    );
}

export default Layout;