import classes from './PagesHeader.module.css'

const PagesHeader = ({title, description})=>{
    return (<>

    <section className={classes.bgImage}>
        <h2>{title}</h2>
        {description && <p className='text-center'>{description}</p>}
    </section>
    </>)
}

export default PagesHeader;